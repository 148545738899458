import { Helmet } from 'react-helmet'
import { Routes, Route, NavLink } from 'react-router-dom'

import Reports from './Reports'
import CodeApprovals from './CodeApprovals'
import AddGames from './AddGames'
import BulkAdd from './BulkAdd'

import './style.css'

const Admin = () => {
  return (
    <div className='content game-codes'>
      <Helmet>
        <title>Roblox Codes | Admin</title>
      </Helmet>
      <div className='container'>
        <div>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'tab-link-active' : 'tab-link'
            }
            to=''
            end
          >
            Reports
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'tab-link-active' : 'tab-link'
            }
            to='codes'
          >
            Codes
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'tab-link-active' : 'tab-link'
            }
            to='bulkadd'
          >
            Bulk Add Codes
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'tab-link-active' : 'tab-link'
            }
            to='games'
          >
            Games
          </NavLink>
        </div>
        <Routes>
          <Route path='codes' element={<CodeApprovals />} />
          <Route path='games' element={<AddGames />} />
          <Route path='bulkadd' element={<BulkAdd />} />
          <Route index element={<Reports />} />
        </Routes>
      </div>
    </div>
  )
}

export default Admin
