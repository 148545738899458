import { useContext } from 'react'
import UserContext from '../contexts/UserContext'
import { logOut } from '../services/users'

import './style.css'

const Footer = () => {
  const { user } = useContext(UserContext)

  return (
    <div className='content'>
      <div className='container'>
        <div className='footer'>
          <div>Roblox Codes 2022</div>
          {user && (
            <button
              className='logout-btn'
              onClick={() => {
                logOut()
                window.location.href = '/'
              }}
            >
              Log Out
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Footer
