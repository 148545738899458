import { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import http from '../../services/http'

import Loading from '../../components/Loading'

const BulkAdd = () => {
  const [games, setGames] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    http.get('/api/games').then((res) => {
      setLoading(false)
      if (res.error) return
      setGames(res.games)
    })
  }, [])

  if (loading) return <Loading />

  return (
    <div>
      <Formik
        initialValues={{ game: '', code: '' }}
        validate={(values) => {
          const errors = {}
          Object.keys(values).forEach((key) => {
            if (!values[key]) errors[key] = 'Required'
          })
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          const codes = values.code.split('\n')
          const newCodes = []
          codes.forEach((code) => {
            const codeInfo = code.split('-')
            if (codeInfo.length > 1)
              newCodes.push({
                reward: codeInfo[0].trim(),
                code: codeInfo[1].trim(),
              })
          })
          http
            .post(`/api/codes`, { game: values.game, codes: newCodes })
            .then((res) => {
              setSubmitting(false)
              if (res.error) return alert(res.error)
            })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className='input-row login-input'>
              <div className='input-label'>Game</div>
              <Field as='select' name='game'>
                {games.map((game) => {
                  return (
                    <option value={game.id} key={game.id}>
                      {game.name}
                    </option>
                  )
                })}
              </Field>
              <ErrorMessage name='game' component='div' className='error' />
            </div>
            <div className='input-row login-input'>
              <div className='input-label'>Code</div>
              <Field name='code' component='textarea' />
              <ErrorMessage name='code' component='div' className='error' />
            </div>
            <div className='login-btn-bar'>
              <button type='submit' disabled={isSubmitting} className='btn'>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BulkAdd
