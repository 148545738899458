const TraderieAd = ({ gameId, gameName, slug }) => {
  return (
    <div className='traderie-ad card'>
      <a
        href={`https://traderie.com/${slug || gameId.replace(/-/g, '')}`}
        target='blank'
      >
        <img
          src='https://cdn.diamondhands.io/robloxcodes/logo_long.png'
          alt='traderie'
        />
        <div>
          Trade your {gameName} items with other traders seamlessly on Traderie.
          Find everything you need in one place.
          <div className='btn traderie-ad-btn'>Sign Up</div>
        </div>
      </a>
    </div>
  )
}

export default TraderieAd
