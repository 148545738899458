import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const SlotStyle = styled.div`
  margin: 10px auto;
  position: relative;
  min-height: ${(props) => (props.height ? props.height : '25vh')};
  width: ${(props) => props.width || '60vw'};

  @media only screen and (max-width: 600px) {
    min-height: ${(props) => props.height || '10vh'};
    width: ${(props) => props.width || '100%'};
    background-color: unset;
  }
`

const StyledSlotMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  @media only screen and (max-width: 600px) {
    background-color: unset;
  }
`

const types = {
  trendi_video: { height: '90px' },
  bottom_rail: { height: '90px' },
  leaderboard: {
    height: '90px',
    sizes: {
      desktop: [
        ['728', '90'],
        ['970', '90'],
      ],
      mobile: [['320', '50']],
    },
  },
  leaderboard_btf: { height: '90px' },
  med_rect_atf: { width: '300px', height: '250px' },
  'right-rail': {
    width: '300px',
    sizes: {
      desktop: [
        ['300', '600'],
        ['300', '250'],
        ['336', '280'],
        ['336', '280'],
        ['160', '600'],
      ],
    },
  },
  anchor: {},
  video: {
    width: '700px',
  },
}

// const isApp = window.localStorage.getItem('app')

const AdSlot = ({ name, index, mobile, style, containerStyle, type }) => {
  const location = useLocation()
  const adObj = useRef(null)

  const config = types[name]
  const id = name + (index && index > 0 ? index : '')

  const isMobile = mobile || window.innerWidth < 600 ? 'mobile' : 'desktop'

  // useEffect(() => {
  //   defineSlot()
  //   window.addEventListener('beforeunload', destroySlots)
  // }, [])

  useEffect(() => {
    destroySlots()
    defineSlot()

    return () => {
      destroySlots()
      window.removeEventListener('beforeunload', destroySlots)
    }
  }, [location.pathname, location.search])

  const defineSlot = () => {
    // {
    //   "refreshLimit": 10,
    //   "refreshTime": 30,
    //   "format": "anchor",
    //   "anchor": "bottom",
    //   "anchorPersistClose": true,
    //   "mediaQuery": "(min-width: 0px)",
    //   "report": {
    //     "enabled": true,
    //     "wording": "Report Ad",
    //     "position": "top-right"
    //   }
    // }

    // {
    //   "format": "video-nc",
    //   "video": {
    //     "mobile": "compact"
    //   }
    // }

    const params = {
      demo: process.env.NODE_ENV !== 'production',
    }
    if (mobile === false) params.mediaQuery = '(min-width: 1025px)'

    if (name === 'anchor') {
      params.format = 'anchor'
      params.anchor = 'bottom'
      params.anchorPersistClose = true
      params.mediaQuery = '(min-width: 0px)'
    }

    if (name === 'video') {
      params.format = 'video-nc'
      params.video = {
        mobile: 'compact',
        float: 'always',
      }
    } else {
      params.refreshLimit = 10
      params.refreshTime = 30
      params.renderVisibleOnly = false
      params.refreshVisibleOnly = true
      params.report = {
        enabled: true,
        wording: 'Report Ad',
        position: 'bottom-right',
      }
    }

    if (name !== 'anchor' && name !== 'video') {
      params.sizes = config.sizes[isMobile]
    }
    adObj.current = window['nitroAds'].createAd(id, params)
  }
  const destroySlots = () => {
    if (adObj.current && adObj.current.onNavigate) adObj.current.onNavigate()
  }

  const isActive = () => {
    if (mobile === undefined) return true
    return (
      (mobile && window.innerWidth < 600) ||
      (mobile === false && window.innerWidth > 600)
    )
  }

  if (!isActive()) return null

  if (!id) return null

  let { height, width } = config
  height = height === '90px' && window.innerWidth < 600 ? '50px' : height
  if (type === 'side') height = 'unset'

  const AdDiv = ({ style }) => {
    return (
      <div style={style}>
        <div id={id} />
      </div>
    )
  }
  if (name === 'anchor' || name === 'video') return <AdDiv />

  return (
    <SlotStyle style={containerStyle} height={height} width={width}>
      <StyledSlotMessage height={height} width={width}>
        <div>Roblox Codes is supported by ads</div>
      </StyledSlotMessage>
      <AdDiv
        style={{
          textAlign: 'center',
          margin: '5px 0px',
          minHeight: height || 'unset',
          minWidth: width || 'unset',
          ...style,
        }}
      />
    </SlotStyle>
  )
}

export default AdSlot
