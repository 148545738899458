import React, { useEffect, useState } from 'react'
import { IoIosCheckmark, IoIosClose } from 'react-icons/io'
import { FaSpinner } from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'

import './style.css'

const EditInput = ({
  className,
  confirm,
  disabled,
  icon,
  id,
  initValue,
  markdown,
  maxLength,
  placeholder,
  type,
  upperCase,
}) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(initValue || '')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  const renderInput = () => {
    const onChange = (e) => {
      const v = e.target.value
      setValue(upperCase ? v.toUpperCase() : v)
    }

    switch (type) {
      case 'textarea':
        return (
          <textarea
            id={id || ''}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
            className='edit-textarea'
          />
        )
      default:
        return (
          <input
            id={id || ''}
            as='input'
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        )
    }
  }

  const currVal = (value !== undefined ? value : initValue) || placeholder

  return (
    <div className={className}>
      {!open && (
        <div
          id={id || ''}
          onClick={() => {
            if (!disabled) setOpen(true)
          }}
          className='edit-value'
          style={{
            color: !value && !initValue ? 'lightgray' : '',
          }}
        >
          {icon}&nbsp;
          {markdown ? (
            <div>
              <ReactMarkdown>{currVal}</ReactMarkdown>
            </div>
          ) : (
            currVal
          )}
          &nbsp;
        </div>
      )}
      {open && (
        <div>
          <div style={{ display: 'flex' }}>
            {renderInput()}
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <button
                className='edit-btn'
                id={id ? id + '_confirm' : ''}
                onClick={() => {
                  setError('')
                  setLoading(true)
                  confirm(value).then((err) => {
                    if (!err) {
                      setOpen(false)
                      setLoading(false)
                    } else {
                      setValue(value)
                      setError(err)
                      setLoading(false)
                    }
                  })
                }}
              >
                {loading ? (
                  <FaSpinner className='fa-spin' size={18} />
                ) : (
                  <IoIosCheckmark />
                )}
              </button>
              <button
                id={id ? id + '_cancel' : ''}
                className='edit-btn edit-cancel'
                onClick={() => {
                  setOpen(false)
                  setValue(initValue)
                  setError('')
                }}
              >
                <IoIosClose />
              </button>
            </div>
          </div>
          <div style={{ color: 'red', fontSize: 14, textAlign: 'left' }}>
            {error}
          </div>
        </div>
      )}
    </div>
  )
}

export default EditInput
