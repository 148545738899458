import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import pagemeta from 'pagemeta'

// <meta property="article:published_time" content="2023-06-12T12:42:17.031Z" />
// <meta property="article:modified_time" content="2023-06-12T12:50:54.259Z" />

const ServerHelmet = ({ data }) => {
  data = data || {}
  data.site = 'robloxcodes.gg'

  const location = useLocation()
  const config = pagemeta.get(location.pathname, data)

  if (!config) return null
  const { title, description, meta } = config
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      {meta &&
        meta.map((tag) => {
          if (tag.property)
            return <meta property={tag.property} content={tag.content} />
          return <meta name={tag.name} content={tag.content} />
        })}
    </Helmet>
  )
}

export default ServerHelmet
