import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HiPlusCircle } from 'react-icons/hi'
import qs from 'qs'

import http from '../../services/http'

import Helmet from '../../components/Helmet'
import SearchBar from '../../components/SearchBar'
import Loading from '../../components/Loading'
import RecentCodes from '../../components/RecentCodes'

import AdSlot from '../../components/AdSlotNitro'

import './style.css'

const Home = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const query = qs.parse(location.search.substring(1))

  const [loading, setLoading] = useState(true)
  const [games, setGames] = useState([])

  useEffect(() => {
    if (!loading) setLoading(true)
    http.get('/api/games', { search: query.search }).then((res) => {
      setLoading(false)
      if (res.error) return
      setGames(res.games)
    })
  }, [query.search])

  return (
    <div className='content'>
      <Helmet />
      <div className='container'>
        <div className='home-top'>
          <div className='home-banner'>
            <h1>Find codes and promos for your favorite Roblox Games</h1>
            <div>
              What makes us different from other code sites? Roblox Codes is the
              only place you can vote on codes and show everyone else which ones
              actually work. You can even suggest games that we don't have right
              on the <Link to='/suggest'>site</Link>! We update our games
              everyday with tons of codes.
            </div>
          </div>
          <AdSlot name='video' />
        </div>
        <RecentCodes />
        <AdSlot name='leaderboard' />
        <div className='home-top'>
          <h2>Games</h2>
          <SearchBar
            init={query.search}
            onSubmit={({ search }) => {
              navigate({ search: qs.stringify({ search }) })
            }}
          />
        </div>
        {loading && <Loading />}
        {!loading && games.length === 0 && (
          <div className='home-no-games'>No games match your search</div>
        )}
        {!loading && (
          <div className='row'>
            {games.map((game) => {
              const { id, name, img } = game
              return (
                <div
                  key={id}
                  className='col-xs-6 col-sm-3'
                  style={{ marginBottom: 15 }}
                >
                  <Link to={`/games/${id}`} className='games-link card'>
                    <div className='games-game'>
                      <img
                        src={
                          img ||
                          `https://cdn.diamondhands.io/robloxcodes/games/${id}.jpg`
                        }
                        alt={name}
                        className='games-img'
                      />
                      <div className='games-name'>{name} Codes</div>
                    </div>
                  </Link>
                </div>
              )
            })}
            <div className='col-xs-6 col-sm-3' style={{ marginBottom: 15 }}>
              <Link to={`/suggest`} className='games-link card'>
                <div className='games-game game-suggest'>
                  <div className='games-name'>
                    Don't see the game you're looking for? Suggest a new game{' '}
                    <HiPlusCircle style={{ verticalAlign: 'middle' }} />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
