import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import http from '../../services/http'

import Helmet from '../../components/Helmet'
import Loading from '../../components/Loading'
import Codes from '../../components/Codes'
import AdSlot from '../../components/AdSlotNitro'
import TraderieAd from '../../components/TraderieAd'

import './style.css'

const Game = () => {
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [codesLoading, setCodesLoading] = useState(true)
  const [game, setGame] = useState({})
  const [codes, setCodes] = useState([])

  useEffect(() => {
    http.get('/api/games', { id: params.id }).then((res) => {
      setLoading(false)
      if (res.error) return
      setGame(res.games)
    })

    http.get('/api/codes', { game: params.id }).then((res) => {
      setCodesLoading(false)
      if (res.error) return
      setCodes(res.codes)
    })
  }, [params.id])

  if (loading) return <Loading />

  const date = new Date()

  return (
    <div className='content game-codes'>
      <Helmet data={{ game: game.name, date }} />
      <div className='container'>
        <div
          className='game-cover'
          style={{
            backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.6),  rgba(0, 0, 0, 0.6)), url(${
              game.img ||
              `https://cdn.diamondhands.io/robloxcodes/games/${game.id}.jpg`
            })`,
          }}
        >
          <h1>{game.name} Codes</h1>
        </div>
        <div className='game-codes'>
          <div style={{ flex: 1 }}>
            <AdSlot name='video' style={{ width: 500 }} />
            {game.traderie && (
              <TraderieAd
                gameId={game.id}
                gameName={game.name}
                slug={game.traderie}
              />
            )}
            <Codes
              loading={codesLoading}
              codes={codes}
              setCodes={setCodes}
              voting
            />
            {game.traderie && (
              <TraderieAd
                gameId={game.id}
                gameName={game.name}
                slug={game.traderie}
              />
            )}
          </div>
          <div>
            <AdSlot
              name='right-rail'
              mobile={false}
              containerStyle={{
                alignItems: 'flex-start',
                marginLeft: 10,
                position: 'sticky',
                top: 20,
                maxHeight: 650,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Game
