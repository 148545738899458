import { useContext } from 'react'
import { DateTime } from 'luxon'
import { HiThumbUp, HiThumbDown, HiFlag } from 'react-icons/hi'
import { can } from 'rbac'

import UserContext from '../../contexts/UserContext'
import http from '../../services/http'

import CopyButton from '../../components/CopyButton'
import Loading from '../../components/Loading'
import AdSlot from '../../components/AdSlotNitro'

import './style.css'

const colors = {
  working: '#27b927',
  expired: '#ff3e3e',
}

const Codes = ({ loading, codes, setCodes, voting, gameInfo, inline }) => {
  const { user } = useContext(UserContext)

  if (loading) return <Loading />

  const vote = (code, up, user_vote) => {
    if (!user) return alert('You must have an account to vote on codes')
    if ((up && user_vote === 1) || (!up && user_vote === -1)) return
    http.post('/api/codes/vote', { code, up }).then((res) => {
      if (res.error) return alert(res.error)
      const codeIndex = codes.findIndex((c) => c.id === code)

      if (up) {
        codes[codeIndex].up++
        if (user_vote) codes[codeIndex].down--
        codes[codeIndex].user_vote = 1
      } else {
        codes[codeIndex].down++
        if (user_vote) codes[codeIndex].up--
        codes[codeIndex].user_vote = -1
      }
      setCodes([...codes])
    })
  }

  const update = (code, status) => {
    if (!can(user, 'ADMIN:CODES')) return
    const newStatus = status === 'working' ? 'expired' : 'working'
    if (
      window.confirm(`Are you sure you want to set this code to ${newStatus}?`)
    )
      http.put('/api/codes', { code, status: newStatus }).then((res) => {
        if (res.error) return alert(res.error)
        const codeIndex = codes.findIndex((c) => c.id === code)
        codes[codeIndex].status = newStatus
        setCodes([...codes])
      })
  }

  const report = (code) => {
    if (!user) return alert('You must have an account to report codes')
    if (
      window.confirm(
        "Are you sure you want to report this code? Only report codes for being inappropriate. If a code isn't working please give it a thumbs down instead."
      )
    )
      http.post('/api/reports', { code }).then((res) => {
        if (res.error) return alert(res.error)
      })
  }

  const codeObjs = codes.map((currCode) => {
    const {
      active,
      code,
      created_at,
      description,
      down,
      game_name,
      id,
      status,
      up,
      user_vote,
      uses,
      verified,
    } = currCode

    const date = DateTime.fromISO(created_at).toLocaleString()

    return (
      <div key={id} className={`${inline ? 'col-xs-12 col-sm-4' : ''}`}>
        <div className='code-content card'>
          {gameInfo && <div className='code-game-info'>{game_name}</div>}
          <div className='code-top' style={{ display: inline ? 'block' : '' }}>
            <div className='code-description'>{description}</div>
            <div>
              <div style={{ display: 'flex' }}>
                <div className='code-container'>
                  <div className='code'>{code}</div>
                  <CopyButton className='btn' text={code}>
                    COPY
                  </CopyButton>
                </div>
              </div>
            </div>
          </div>
          <div className='code-bot'>
            <div className='code-bot-left'>
              {!active ? (
                <div>PENDING</div>
              ) : (
                <button
                  className='code-status'
                  style={{
                    backgroundColor: colors[status],
                  }}
                  onClick={() => {
                    update(id, status)
                  }}
                >
                  {status}
                </button>
              )}
              {voting && (
                <div className='code-vote'>
                  <button
                    className='code-vote-btn'
                    onClick={() => vote(id, true, user_vote)}
                  >
                    <HiThumbUp
                      style={{ color: user_vote === 1 ? '#27b927' : '' }}
                    />
                  </button>
                  {up}
                  <button
                    className='code-vote-btn'
                    onClick={() => vote(id, false, user_vote)}
                  >
                    <HiThumbDown
                      style={{ color: user_vote === -1 ? '#ff3e3e' : '' }}
                    />
                  </button>
                  {down}
                </div>
              )}
              {uses > 0 && <div>{uses} uses</div>}
            </div>
            <div className='code-bot-right'>
              <div>Added: {date}</div>
              {!verified && (
                <button className='code-vote-btn' onClick={() => report(id)}>
                  <HiFlag />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  })

  if (!inline)
    for (let itemIndex = 5; itemIndex < codeObjs.length; itemIndex += 6) {
      codeObjs.splice(
        itemIndex,
        0,
        <AdSlot name='leaderboard' index={itemIndex} />
      )
    }

  return (
    <div className={inline ? 'row' : ''}>
      {codeObjs}
      {!loading && codes.length === 0 && <div>No Codes</div>}
    </div>
  )
}

export default Codes
