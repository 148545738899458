const pathRegexp = require('path-to-regexp')

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const configs = {
  '/': ({}) => ({
    title:
      'Roblox Codes | Get the latest Roblox codes and promos for all games',
    description:
      'Get Roblox codes for every game and promos out there. Even submit your own codes.',
  }),
  '/codes': ({ site }) => ({
    title: `Latest Roblox Codes | ${site}`,
    description:
      'Get Roblox codes for every game and promos out there. Even submit your own codes.',
  }),
  '/login': ({ site }) => ({
    title: `Log In | ${site}`,
    description:
      'Log in to get Roblox codes for every game and promos out there. Even submit your own codes.',
  }),
  '/signup': ({ site }) => ({
    title: `Sign Up | ${site}`,
    description:
      'Sign up get Roblox codes for every game and promos out there. Even submit your own codes.',
  }),
  '/games/:id': ({ site, game, date, created_at, updated_at }) => ({
    title: `Roblox ${game} Codes ${
      monthNames[date.getMonth()]
    } ${date.getFullYear()} | ${site}`,
    description: `Get the latest ${game} codes and promos for all games`,
    meta: [
      { property: 'og:type', content: 'article' },
      { property: 'article:published_time', content: created_at },
      { property: 'article:modified_time', content: updated_at },
    ],
  }),
}

const matches = Object.keys(configs).map((path) => {
  return { path, regex: pathRegexp(path) }
})

module.exports = {
  get: (path, data) => {
    let config = configs[path]
    // If not exact match try to match the url
    if (!config) {
      let matchPath = ''
      matches.forEach((match) => {
        if (match.regex.exec(path)) {
          matchPath = match.path
          return
        }
      })
      if (!matchPath) return
      config = configs[matchPath]
    }
    return config(data)
  },
}
