import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import http from '../../services/http'

import Loading from '../../components/Loading'
import Codes from '../../components/Codes'
import AdSlot from '../../components/AdSlotNitro'

import './style.css'

const Game = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [codesLoading, setCodesLoading] = useState(true)
  const [user, setUser] = useState({})
  const [codes, setCodes] = useState([])

  useEffect(() => {
    http.get('/api/users', { id: params.id }).then((res) => {
      setLoading(false)
      if (res.error) return
      if (res.users) setUser(res.users)
    })

    http.get('/api/codes', { user: params.id, gameInfo: true }).then((res) => {
      setCodesLoading(false)
      if (res.error) return
      setCodes(res.codes)
    })
  }, [params.id])

  if (loading) return <Loading />
  if (!user.id) return navigate('/')

  return (
    <div className='content game-codes'>
      <Helmet>
        <title>Roblox Codes | {user.username}</title>
      </Helmet>
      <div className='container'>
        <h1>{user.username}</h1>
        <h2>Your Submitted Codes</h2>
        <div className='game-codes'>
          <div style={{ flex: 1 }}>
            <Codes loading={codesLoading} codes={codes} gameInfo />
          </div>
          <div style={{ width: 300 }}>
            <AdSlot
              name='right-rail'
              mobile={false}
              containerStyle={{
                alignItems: 'flex-start',
                marginLeft: 10,
                position: 'sticky',
                top: 20,
                maxHeight: 650,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Game
