import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import http from '../../services/http'

import Loading from '../../components/Loading'

const CodeApprovals = () => {
  const [loading, setLoading] = useState(true)
  const [codes, setCodes] = useState([])

  useEffect(() => {
    http.get('/api/codes', { admin: true }).then((res) => {
      setLoading(false)
      if (res.error) return
      setCodes(res.codes)
    })
  }, [])

  const approve = (code) => {
    http.post('/api/codes/approve', { code }).then((res) => {
      if (res.error) return alert(res.error)
      setCodes(codes.filter((c) => c.id !== code))
    })
  }

  const deny = (code) => {
    http.post('/api/codes/deny', { code }).then((res) => {
      if (res.error) return alert(res.error)
      setCodes(codes.filter((c) => c.id !== code))
    })
  }

  if (loading) return <Loading />

  return (
    <div className='content game-codes'>
      <Helmet>
        <title>Roblox Codes | Admin</title>
      </Helmet>
      <div className='container'>
        {codes.map((currCode) => {
          const { game_id, id, code, description } = currCode
          return (
            <div className='admin-code card' key={id}>
              <div>
                {game_id}: <b>{code}</b> - {description}
              </div>
              <div>
                <button className='btn' onClick={() => approve(id)}>
                  Approve
                </button>
                &nbsp;&nbsp;
                <button className='btn-alt2' onClick={() => deny(id)}>
                  Deny
                </button>
              </div>
            </div>
          )
        })}
        {!loading && codes.length === 0 && <div>No codes to approve.</div>}
      </div>
    </div>
  )
}

export default CodeApprovals
