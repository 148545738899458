import { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link, useNavigate } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'
import http from '../../services/http'
import { setJWT, setUser } from '../../services/users'

import Helmet from '../../components/Helmet'

import './style.css'

const Login = () => {
  const { setUser: setCUser } = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <div className='login'>
      <Helmet />
      <div className='container'>
        <h2>Login</h2>

        <Formik
          initialValues={{ username: '', password: '' }}
          validate={(values) => {
            const errors = {}
            Object.keys(values).forEach((key) => {
              if (!values[key]) errors[key] = 'Required'
            })
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            http.post(`/api/login`, values).then((res) => {
              setSubmitting(false)
              if (res.error) return alert(res.error)
              setJWT(res.jwt)
              setUser(res.user)
              setCUser(res.user)
              navigate('/')
            })
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className='input-row login-input'>
                <div className='input-label'>Username</div>
                <Field type='username' name='username' />
                <ErrorMessage
                  name='username'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>Password</div>
                <Field type='password' name='password' />
                <ErrorMessage
                  name='password'
                  component='div'
                  className='error'
                />
              </div>
              <div className='login-btn-bar'>
                <button type='submit' disabled={isSubmitting} className='btn'>
                  Login
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='login-switch-container'>
          Don't have an account? <Link to={'/signup'}>Sign Up</Link>
        </div>
      </div>
    </div>
  )
}

export default Login
