import Helmet from '../../components/Helmet'
import RecentCodes from '../../components/RecentCodes'

const CodesPage = () => {
  return (
    <div className='content'>
      <Helmet />
      <div className='container'>
        <RecentCodes size={50} inline={false} />
      </div>
    </div>
  )
}

export default CodesPage
