import { useState } from 'react'

import FileUpload from '../../components/FileUpload'
import EditInput from '../../components/EditInput'

const AddGame = ({ game, update }) => {
  const [imageUrl, setImageUrl] = useState('')

  const { id, name, img, active } = game

  return (
    <div className='admin-game card' key={id}>
      <div className='admin-game-left'>
        <FileUpload
          message={
            <img
              src={
                img ||
                imageUrl ||
                `https://cdn.diamondhands.io/robloxcodes/games/${id}.jpg`
              }
              alt={name}
              className='admin-game-img'
            />
          }
          handleUpload={(image, blob) => {
            if (image) {
              update(id, { image: blob })
              setImageUrl(image)
            }
          }}
          style={{ padding: 0, borderRadius: 0 }}
          noWarning
        />
        <div className='admin-game-name'>
          {id}:{' '}
          <EditInput
            initValue={name}
            confirm={async (value) => {
              update(id, { name: value })
            }}
            placeholder='Game name'
          />
        </div>
      </div>
      {active === null && (
        <div className='admin-game-right'>
          <button className='btn' onClick={() => update(id, { active: true })}>
            Approve
          </button>
          &nbsp;&nbsp;
          <button
            className='btn-alt2'
            onClick={() => update(id, { active: false })}
          >
            Deny
          </button>
        </div>
      )}
    </div>
  )
}

export default AddGame
