import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { can } from 'rbac'

import UserContext, { UserContextProvider } from './contexts/UserContext'

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home'
import Game from './pages/Game'
import CodesPage from './pages/Codes'
import Login from './pages/Login'
import Signup from './pages/Login/Signup'
import Submit from './pages/Submit'
import Profile from './pages/Profile'
import Admin from './pages/Admin'
import Suggest from './pages/Suggest'

import AdSlot from './components/AdSlotNitro'

import 'flexboxgrid/css/flexboxgrid.min.css'
import './App.css'

function App() {
  return (
    <div className='App'>
      <UserContextProvider>
        <BrowserRouter>
          <Navbar />
          <UserContext.Consumer>
            {({ user }) => {
              return (
                <Routes>
                  <Route path='login' element={<Login />} />
                  <Route path='signup' element={<Signup />} />
                  {user && <Route path='submit' element={<Submit />} />}
                  {user && <Route path='suggest' element={<Suggest />} />}
                  <Route path='codes' element={<CodesPage />} />
                  <Route path='games/:id' element={<Game />} />
                  <Route path='profile/:id' element={<Profile />} />
                  {can(user, 'ADMIN:CODES') && (
                    <Route path='admin/*' element={<Admin />} />
                  )}
                  <Route index element={<Home />} />
                  <Route path='*' element={<Navigate to='/login' replace />} />
                </Routes>
              )
            }}
          </UserContext.Consumer>
          <Footer />
          <AdSlot name='anchor' />
        </BrowserRouter>
      </UserContextProvider>
    </div>
  )
}

export default App
