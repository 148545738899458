import { useEffect, useState } from 'react'

import http from '../../services/http'

import Codes from '../Codes'

const RecentCodes = ({ size, inline }) => {
  const [loading, setLoading] = useState(true)
  const [codes, setCodes] = useState([])

  useEffect(() => {
    http
      .get('/api/codes', {
        status: 'working',
        latest: true,
        gameInfo: true,
        limit: size || 6,
      })
      .then((res) => {
        setLoading(false)
        if (res.error) return
        setCodes(res.codes)
      })
  }, [])

  return (
    <div>
      <h2>Recently Added Codes</h2>
      <Codes
        loading={loading}
        codes={codes}
        gameInfo
        inline={inline !== undefined ? inline : true}
      />
    </div>
  )
}

export default RecentCodes
