import { useContext, useEffect, useState } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { can } from 'rbac'
import qs from 'qs'

import http from '../../services/http'

import UserContext from '../../contexts/UserContext'

import './style.css'

const Navbar = () => {
  const { user } = useContext(UserContext)
  const location = useLocation()
  const [games, setGames] = useState([])

  useEffect(() => {
    const query = qs.parse(location.search.substring(1))
    if (query.ref && typeof query.ref === 'string' && query.ref.length)
      localStorage.setItem('fromRef', query.ref)
  }, [location.search])

  useEffect(() => {
    http.get('/api/games', { random: true, limit: 10 }).then((res) => {
      if (res.error) return
      setGames(res.games)
    })
  }, [])

  return (
    <div className='navbar-container'>
      <div className='content' style={{ padding: 0 }}>
        <div className='container'>
          <div className='navbar'>
            <div className='nav-left'>
              <Link to='/'>
                <div className='logo'>
                  <img
                    src='https://cdn.diamondhands.io/robloxcodes/logo.png'
                    alt='reality casts'
                  />
                </div>
              </Link>
              <div class='dropdown'>
                <Link to='/'>Games</Link>
                <div class='dropdown-content'>
                  {games.map((game) => {
                    return (
                      <div className='dropdown-item'>
                        <Link to={`/games/${game.id}`}>{game.name}</Link>
                      </div>
                    )
                  })}
                </div>
              </div>
              <Link to='/codes'>Codes</Link>
              <a href='https://traderie.com/?ref=ROBLOXCODESGG'>
                Trade Roblox Items
              </a>
            </div>
            <div>
              {user && can(user, 'ADMIN:CODES') && (
                <Link to='/admin'>ADMIN</Link>
              )}
              &nbsp;&nbsp;
              <NavLink to={user ? '/submit' : '/login'} className='btn-alt'>
                SUBMIT CODE
              </NavLink>
              &nbsp;&nbsp;
              {user && <Link to={`/profile/${user.id}`}>{user.username}</Link>}
              {!user && (
                <>
                  <NavLink to='/login'>Log In</NavLink>&nbsp;&nbsp;
                  <NavLink to='/signup'>Sign Up</NavLink>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
