import { Formik, Form, Field } from 'formik'
import { HiSearch } from 'react-icons/hi'

import './style.css'

const SearchBar = ({ init, onSubmit }) => {
  return (
    <Formik
      initialValues={{ search: init || '' }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='search'>
            <div>
              <Field
                name='search'
                className='search-input'
                placeholder='Search games...'
              />
            </div>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn search-btn'
            >
              <HiSearch />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SearchBar
