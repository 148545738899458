module.exports = {
  can: (user, permission) => {
    if (!user) return false
    if (!permission) return false
    if (!user.roles) return false
    let canDo = false
    user.roles.forEach((role) => {
      if (role.permissions && role.permissions.includes(permission))
        canDo = true
    })
    return canDo
  },
}
