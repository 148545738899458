import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import http from '../../services/http'
import { CAPTCHA_KEY } from '../../services/users'

import Loading from '../../components/Loading'

const Submit = () => {
  const [games, setGames] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    http.get('/api/games').then((res) => {
      setLoading(false)
      if (res.error) return
      setGames(res.games)
    })
  }, [])

  const navigate = useNavigate()

  if (loading) return <Loading />

  return (
    <div className='content'>
      <Helmet>
        <title>Submit a new Roblox code or promo | Roblox Codes</title>
      </Helmet>
      <div className='container'>
        <h2>Submit a new Roblox Code</h2>
        <Formik
          initialValues={{ game: '', code: '', reward: '', captcha: '' }}
          validate={(values) => {
            const errors = {}
            Object.keys(values).forEach((key) => {
              if (!values[key]) errors[key] = 'Required'
            })
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            http.post(`/api/codes`, values).then((res) => {
              setSubmitting(false)
              if (res.error) return alert(res.error)
              navigate('/')
            })
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className='input-row login-input'>
                <div className='input-label'>Game</div>
                <Field as='select' name='game'>
                  {games.map((game) => {
                    return (
                      <option value={game.id} key={game.id}>
                        {game.name}
                      </option>
                    )
                  })}
                </Field>
                <ErrorMessage name='game' component='div' className='error' />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>Code</div>
                <Field name='code' />
                <ErrorMessage name='code' component='div' className='error' />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>Reward Description</div>
                <Field name='reward' />
                <ErrorMessage name='reward' component='div' className='error' />
              </div>
              <div style={{ marginBottom: 10 }}>
                <ReCAPTCHA
                  sitekey={CAPTCHA_KEY}
                  onChange={(e) => {
                    setFieldValue('captcha', e)
                  }}
                />
              </div>
              <div className='login-btn-bar'>
                <button type='submit' disabled={isSubmitting} className='btn'>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default Submit
