import http from './http'

// PROD
export const CAPTCHA_KEY =
  process.env.NODE_ENV === 'production'
    ? '6LdyPdUfAAAAANK3OdCli5i5ReRDiovzbDkSWNs-'
    : '6LfbOu8UAAAAANWJzo7WoArD59cT8yJtA-kz4aem'

export const getJWT = () => {
  const token = localStorage.getItem('jwt')
  if (token) return `Bearer ${token}`
  return null
}

export const setJWT = (jwt) => {
  localStorage.setItem('jwt', jwt)
}

export const logOut = () => {
  try {
    localStorage.removeItem('jwt')
    return localStorage.removeItem('user')
  } catch {
    return
  }
}

export const getUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'))
  } catch (err) {
    return null
  }
}

export const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const validateUsername = (username) => {
  return /^[\w\d \\.]{3,15}$/.test(username)
}

export const getUsers = (qsParams, signal) => {
  return http.get(`/users`, qsParams, { signal })
}

export const getAccount = (userId, options) => {
  return http.get(`/accounts`, { user: userId }, options)
}
