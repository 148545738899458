import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import http from '../../services/http'

import Loading from '../../components/Loading'

const Reports = () => {
  const [loading, setLoading] = useState(true)
  const [reports, setReports] = useState([])

  useEffect(() => {
    http.get('/api/reports', { status: 'open' }).then((res) => {
      setLoading(false)
      if (res.error) return
      setReports(res.reports)
    })
  }, [])

  const remove = (code) => {
    if (window.confirm('Are you sure you want to remove this code?'))
      http.post('/api/reports/remove', { code }).then((res) => {
        if (res.error) return alert(res.error)
        setReports(reports.filter((c) => c.id !== code))
      })
  }

  const close = (code) => {
    http.post('/api/reports/close', { code }).then((res) => {
      if (res.error) return alert(res.error)
      setReports(reports.filter((c) => c.id !== code))
    })
  }

  if (loading) return <Loading />

  return (
    <div className='content game-reports'>
      <Helmet>
        <title>Roblox Reports | Admin</title>
      </Helmet>
      <div className='container'>
        {reports.map((currCode) => {
          const {
            id,
            game_id,
            code,
            description,
            reports: codeReports,
          } = currCode
          return (
            <div className='admin-code card' key={id}>
              <div className='admin-report-count'>{codeReports.length}</div>
              <div>
                {game_id}: <b>{code}</b> - {description}
              </div>
              <div>
                <button className='btn' onClick={() => remove(id)}>
                  Remove Code
                </button>
                &nbsp;&nbsp;
                <button className='btn-alt2' onClick={() => close(id)}>
                  Close Reports
                </button>
              </div>
            </div>
          )
        })}
        {!loading && reports.length === 0 && <div>No reports</div>}
      </div>
    </div>
  )
}

export default Reports
