import React from 'react'
import Dropzone from 'react-dropzone'

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

const dataURItoBlob = (dataURI) => {
  let binary = atob(dataURI.split(',')[1])
  let array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
}

class FileUpload extends React.Component {
  onDrop = (acceptedFiles, rejectedFiles) => {
    let { handleUpload } = this.props
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        getBase64(file).then((dataUrl) => {
          let blob = dataURItoBlob(dataUrl)
          handleUpload(dataUrl, blob, file.name)
        })
      })
    } else {
      if (rejectedFiles[0].errors) {
        alert(rejectedFiles[0].errors[0].message)
      } else {
        alert('Invalid file format.')
      }
    }
  }

  render = () => {
    const { noWarning, style } = this.props
    return (
      <Dropzone onDrop={this.onDrop} maxSize={1000000} accept={'image/*'}>
        {({ getRootProps, getInputProps }) => (
          <div>
            <section
              style={{
                border: '1px dotted white',
                borderRadius: 5,
                padding: 10,
                maxWidth: 200,
                ...style,
              }}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div>{this.props.message}</div>
              </div>
            </section>
            {!noWarning && (
              <div style={{ color: 'gray' }}>Max image size: 200KB</div>
            )}
          </div>
        )}
      </Dropzone>
    )
  }
}

export default FileUpload
