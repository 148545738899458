import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import http from '../../services/http'
import { CAPTCHA_KEY } from '../../services/users'

const Submit = () => {
  const [submitted, setSubmitted] = useState(false)

  return (
    <div className='content'>
      <Helmet>
        <title>
          Suggest a new Roblox game for codes or promos | Roblox Codes
        </title>
      </Helmet>
      <div className='container'>
        <h2>Suggest a new Roblox Game</h2>
        {submitted && (
          <div>
            <h1>
              Thanks for submitting your game suggestion. It'll take some time
              to look over but hopefully we'll add it soon!
            </h1>
            <Link to='/' className='btn'>
              Continue back to site
            </Link>
          </div>
        )}
        {!submitted && (
          <Formik
            initialValues={{ name: '', captcha: '' }}
            validate={(values) => {
              const errors = {}
              Object.keys(values).forEach((key) => {
                if (!values[key]) errors[key] = 'Required'
              })
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              http.blob(`/api/games`, {}, values).then((res) => {
                setSubmitting(false)
                if (res.error) return alert(res.error)
                setSubmitted(true)
              })
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className='input-row login-input'>
                  <Field name='name' placeholder='Game name' />
                  <ErrorMessage name='name' component='div' className='error' />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <ReCAPTCHA
                    sitekey={CAPTCHA_KEY}
                    onChange={(e) => {
                      setFieldValue('captcha', e)
                    }}
                  />
                </div>
                <div className='login-btn-bar'>
                  <button type='submit' disabled={isSubmitting} className='btn'>
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  )
}

export default Submit
