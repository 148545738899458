import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import http from '../../services/http'

import Loading from '../../components/Loading'
import FileUpload from '../../components/FileUpload'

import AddGame from './AddGame'

const AddGames = () => {
  const [loading, setLoading] = useState(true)
  const [games, setGames] = useState([])
  const [image, setImage] = useState('')

  useEffect(() => {
    http
      .get('/api/games', {
        active: 'admin',
        orderBy: 'created_at',
        orderDir: 'desc',
      })
      .then((res) => {
        setLoading(false)
        if (res.error) return
        setGames(res.games)
      })
  }, [])

  if (loading) return <Loading />

  const update = (game, { name, image, active }) => {
    const payload = { game }
    if (name) payload.name = name
    if (active !== undefined) payload.active = active
    const fd = new FormData()
    if (image) fd.append('image', image)
    http.blob('/api/games', fd, payload, 'PUT').then((res) => {
      if (res.error) return
      const gameIndex = games.findIndex((g) => g.id === game)
      games[gameIndex] = { ...games[gameIndex], ...payload }
      setGames([...games])
    })
  }

  return (
    <div className='content game-games'>
      <Helmet>
        <title>Roblox Codes | Admin</title>
      </Helmet>
      <div className='container'>
        <h3>Add New Game</h3>
        <Formik
          initialValues={{ name: '' }}
          validate={(values) => {
            const errors = {}
            Object.keys(values).forEach((key) => {
              if (!values[key]) errors[key] = 'Required'
            })
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            const fd = new FormData()
            fd.append('image', image)

            http.blob('/api/games', fd, { name: values.name }).then((res) => {
              setSubmitting(false)
              if (res.error) return alert(res.error)
              games.push()
              setGames()
            })
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <div className='input-row login-input'>
                <div className='input-label'>Name</div>
                <Field name='name' />
                <ErrorMessage name='name' component='div' className='error' />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>Image</div>
                <FileUpload
                  message='Drag & Drop or Upload image'
                  handleUpload={(image, blob) => {
                    if (image) {
                      setImage(blob)
                      setFieldValue('imageUrl', image)
                    }
                  }}
                />
                {values.imageUrl && (
                  <img
                    src={values.imageUrl}
                    style={{ maxWidth: 300 }}
                    alt='team logo'
                  />
                )}
                <ErrorMessage
                  name='imageUrl'
                  component='div'
                  className='error'
                />
              </div>
              <div className='login-btn-bar'>
                <button type='submit' disabled={isSubmitting} className='btn'>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div>
          {games.map((game) => (
            <AddGame game={game} update={update} />
          ))}
          {!loading && games.length === 0 && <div>No games</div>}
        </div>
      </div>
    </div>
  )
}

export default AddGames
