import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CopyButton = ({ text, label, style }) => {
  const [copied, setCopied] = useState(false)

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      }}
    >
      <div className='btn' style={style}>
        {label || (copied ? 'COPIED!' : 'COPY')}
      </div>
    </CopyToClipboard>
  )
}

export default CopyButton
