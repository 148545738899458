import { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import UserContext from '../../contexts/UserContext'
import http from '../../services/http'
import { CAPTCHA_KEY, setJWT, setUser } from '../../services/users'

import Helmet from '../../components/Helmet'

const validateUsername = (username) => {
  return /^[\w\d \\.]{3,15}$/.test(username)
}

const verifyPassword = (pw) => {
  return /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/.test(pw)
}

const Signup = () => {
  const { setUser: setCUser } = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <div className='login'>
      <Helmet />
      <div className='container'>
        <h2>Create Account</h2>

        <Formik
          initialValues={{
            username: '',
            password: '',
            password2: '',
            email: '',
            captcha: '',
            source: localStorage.getItem('fromRef') || null,
          }}
          validate={(values) => {
            const errors = {}
            if (!values.username) errors.username = 'Required'
            if (!values.password) errors.password = 'Required'
            if (!values.password2) errors.password2 = 'Required'
            if (values.password2 !== values.password)
              errors.password2 = 'Passwords do not match'

            if (values.username && !validateUsername(values.username)) {
              errors.username = 'Invalid username'
            }
            if (
              process.env.NODE_ENV === 'production' &&
              values.password &&
              !verifyPassword(values.password)
            ) {
              errors.password =
                'Your password must be at least 8 characters and contain at least 1 capital letter, 1 small letter, and 1 number'
            }

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            http.post(`/api/register`, values).then((res) => {
              setSubmitting(false)
              if (res.error) return alert(res.error)
              setJWT(res.jwt)
              setUser(res.user)
              setCUser(res.user)
              navigate('/')
            })
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className='input-row login-input'>
                <div className='input-label'>
                  Username<span className='error'>*</span>
                </div>
                <Field type='text' name='username' />
                <ErrorMessage
                  name='username'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  Password<span className='error'>*</span>
                </div>
                <Field type='password' name='password' />
                <ErrorMessage
                  name='password'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  Confirm password<span className='error'>*</span>
                </div>
                <Field type='password' name='password2' />
                <ErrorMessage
                  name='password2'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>Email</div>
                <Field type='email' name='email' />
                <ErrorMessage name='email' component='div' className='error' />
              </div>
              <ReCAPTCHA
                sitekey={CAPTCHA_KEY}
                onChange={(e) => {
                  setFieldValue('captcha', e)
                }}
              />
              {/* <div style={{ color: 'gray', marginBottom: 10 }}>
                Pressing register
                <Link
                  style={{ textDecoration: 'underline' }}
                  to={`/termsofservice`}
                >
                  Terms of Service
                </Link>{' '}
                as well as{' '}
                <Link
                  style={{ textDecoration: 'underline' }}
                  to={`/privacypolicy`}
                >
                  Privacy Policy
                </Link>
              </div> */}
              <div className='login-btn-bar' style={{ marginTop: 10 }}>
                <button type='submit' disabled={isSubmitting} className='btn'>
                  Register
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='login-switch-container'>
          Already have an account? <Link to={'/login'}>Log In</Link>
        </div>
      </div>
    </div>
  )
}

export default Signup
